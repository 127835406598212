import React, { Component } from 'react';
import { navigate } from 'gatsby';

class BlogPage extends Component {
  componentDidMount() {
    navigate('/', { replace: true });
  }

  render() {
    return <div className='about-container' />;
  }
}

export default BlogPage;
